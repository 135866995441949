export const Currency = {
    INR: 'inr',
    USD: 'usd'
}
const CurrencyFormat = {
    inr: { name: "INR", locale: 'en-IN', wordingInteger: 'Rupee', wordingDecimal: 'Paisa' },
    usd: { name: "USD", locale: 'en-US', wordingInteger: 'Doller', wordingDecimal: '' }
}

export const CurrencyFormatter = (currency, value) => {
    let formatter = new Intl.NumberFormat(CurrencyFormat[currency].locale, { style: 'currency', currency: CurrencyFormat[currency].name, maximumFractionDigits: 2 });
    return formatter.format(value);
}

export const setFormikValueWithUpperCase = (event, formik) => {
    formik.setFieldTouched([event.target.id], true);
    formik.setFieldValue([event.target.id], event.target.value.toUpperCase());
}

export const setFormikValueWithLowerCase = (event, formik) => {
    formik.setFieldTouched([event.target.id], true);
    formik.setFieldValue([event.target.id], event.target.value.toLowerCase());
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    console.log('size', `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`);
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const CurrencyFormatterWithOutSymbol = (currency, value) => {
    let formatter = new Intl.NumberFormat(CurrencyFormat[currency].locale, { style: 'decimal', currency: CurrencyFormat[currency].name, maximumFractionDigits: 2 });
    return formatter.format(value);
}
