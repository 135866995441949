const CreditorsType = {
    COMPANY: 20,
    INDIVIDUAL: 1,
    LLP: 19,
    BANK: 16,
    FINSTITUTION: 17,
    NBFC: 18,
    PARTNERSHIPFIRM:21,
    PROPBBUSINESS: 22,
    COOPERATIVESOCIETY: 6,
    GOVT_BODY: 13
}

const FinancialCreditorTypes = [
    {
        id: CreditorsType.COMPANY,
        title: 'Company',
        partyLocale: 'Company'
    },
    {
        id: CreditorsType.INDIVIDUAL,
        title: 'Individual',
        partyLocale: 'First'
    },
    {
        id: CreditorsType.LLP,
        title: 'Limited Liability Partnership',
        partyLocale: 'LLP'
    },
    {
        id: CreditorsType.BANK,
        title: 'Bank',
        partyLocale: 'Bank'
    },
    {
        id: CreditorsType.FINSTITUTION,
        title: 'Financial Institution',
        partyLocale: 'Financial Institution'
    },
    {
        id: CreditorsType.NBFC,
        title: 'NBFC (Non-Banking Financial Companies)',
        partyLocale: 'Company'
    },
    {
        id: CreditorsType.PARTNERSHIPFIRM,
        title: 'Partnership Firm',
        partyLocale: 'Partnership Firm'
    },
    {
        id: CreditorsType.PROPBBUSINESS,
        title: 'Proprietorship Business',
        partyLocale: 'Proprietorship Business'
    },
    {
        id: CreditorsType.COOPERATIVESOCIETY,
        title: 'Co-Operative Society',
        partyLocale: 'Co-Operative Society'
    },
    {
        id: CreditorsType.GOVT_BODY,
        title: 'Government Body',
        partyLocale: 'Government Body'
    }
]

const IdentificationType = {
    CIN     : 52,
    GSTIN   : 9,
    PAN     : 4,
    PASSPORT: 2,
    VOTERID : 3,
    IBBI_REG_IP: 8,
    IBBI_VALUER_PLANT_AND_MACHINERY: 10,
    IBBI_VALUER_LAND_AND_BUILDING: 11,
    IBBI_VALUER_SECURITIES_AND_FINANCIAL_ASSETS:12,
    GOVT_BODY_ICMS_ID: 13
}

const identificationNumberToIdentificationType = {
    20: 'COMPANY',
    1: 'INDIVIDUAL',
    19: 'LLP',
    16: 'BANK',
    17: 'FINSTITUTION',
    18: 'NBFC',
    21: 'PARTNERSHIPFIRM',
    22: 'PROPBBUSINESS',
    6: 'COOPERATIVESOCIETY'
}

const IdentificationByPartyType = {
    [CreditorsType.INDIVIDUAL] : [
        {
            id   : IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id   : IdentificationType.PASSPORT,
            title: 'Passport'
        },
        {
            id: IdentificationType.VOTERID,
            title: 'Voter Id'
        },
    ],
    [CreditorsType.COMPANY] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [CreditorsType.LLP] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [CreditorsType.BANK] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [CreditorsType.FINSTITUTION] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        }
    ],
    [CreditorsType.NBFC] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        }
    ],
    [CreditorsType.PARTNERSHIPFIRM] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [CreditorsType.PROPBBUSINESS] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [CreditorsType.COOPERATIVESOCIETY] : [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        }
    ],
    [CreditorsType.GOVT_BODY] : [
        {
            id: IdentificationType.GOVT_BODY_ICMS_ID,
            title: ''
        }
    ]
}

const IdentificationCompanyType = [
    {
        id: IdentificationType.CIN,
        title: 'CIN'
    },
    {
        id: IdentificationType.GSTIN,
        title: 'GSTIN'
    },
]

const IdentificationIndiType = [
    {
        id: IdentificationType.PAN,
        title: 'PAN'
    },
    {
        id: IdentificationType.PASSPORT,
        title: 'Passport'
    },
    {
        id: IdentificationType.VOTERID,
        title: 'Voter Id'
    },
]

const valuerAuthRepIdentification = [
    {
        id: IdentificationType.PAN,
        title: 'PAN'
    },
    {
        id: IdentificationType.PASSPORT,
        title: 'Passport'
    },
    {
        id: IdentificationType.VOTERID,
        title: 'Voter Id'
    },
    {
        id: IdentificationType.IBBI_REG_IP,
        title: 'IBBI - IP - Reg.No.'
    },
    {
        id: IdentificationType.IBBI_VALUER_PLANT_AND_MACHINERY,
        title: 'IBBI - Plant and Machiner - Reg.No.'
    },
    {
        id: IdentificationType.IBBI_VALUER_LAND_AND_BUILDING,
        title: 'IBBI - Land and Building - Reg.No'
    },
    {
        id: IdentificationType.IBBI_VALUER_SECURITIES_AND_FINANCIAL_ASSETS,
        title: 'IBBI - Securities or Financial Assets - Reg.No.'
    },
]

const serviceProvderRoleCd = {
    IRP: {
        64: {
            title: 'Proposed IRP'
        },
        26: {
            title: 'IRP'
        },
        "Proposed": {
            cd: 64,
        },
        "Appointed": {
            cd: 26,
        },
    },
    RP: {
        65: {
            title: 'Proposed RP'
        },
        27: {
            title: 'RP'
        },
        "Proposed": {
            cd: 65,
        },
        "Appointed": {
            cd: 27,
        },
    },
    LIQUIDATOR: {
        76: {
            title: 'Proposed Liquidator'
        },
        28: {
            title: 'Liquidator'
        },
        "Proposed": {
            cd: 76,
        },
        "Appointed": {
            cd: 28,
        },
    },
    AUTHORISED_REPRESENTSTIVE: {
        63: {
            title: 'Proposed Authorized Representative'
        },
        9: {
            title: 'Authorized Representative'
        },
        "Proposed": {
            cd: 63,
        },
        "Appointed": {
            cd: 9,
        },
    },  
};

const ServiceProviderPartyRoles = {
    "Proposed IRP": serviceProvderRoleCd.IRP,
    "IRP": serviceProvderRoleCd.IRP, 
    "Proposed RP": serviceProvderRoleCd.RP,
    "RP": serviceProvderRoleCd.RP,
    "Liquidator": serviceProvderRoleCd.LIQUIDATOR,
    "Proposed Authorized Representative": serviceProvderRoleCd.AUTHORISED_REPRESENTSTIVE,
    "Authorized Representative": serviceProvderRoleCd.AUTHORISED_REPRESENTSTIVE  
}

const valuerIdentificationType = {

}

export { FinancialCreditorTypes, CreditorsType, IdentificationCompanyType, IdentificationType, IdentificationIndiType, valuerAuthRepIdentification,
    ServiceProviderPartyRoles, valuerIdentificationType, IdentificationByPartyType, identificationNumberToIdentificationType }
