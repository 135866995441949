import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useFormik }                    from 'formik'
import React, { useState }              from 'react'
import { useTranslation }               from 'react-i18next'
import * as Yup                         from 'yup'
import ICMSLoader                       from '../../icms-loader/ICMSLoader'
import IcmsSnackbar                     from '../../icms-snackbar/IcmsSnackbar'
import { BankAccountTypeCd }            from '../../../icms/file-claim/fc-file-claim/bank-list/BankGenericCodes'
import { ICMSAxiosInterceptor }         from '../../../config/axios.interceptor'
import BankAccountFields                from '../../bank/BankAccountFields'
import { RegEx }                        from '../../GenericCodes'
import { ICMSButton } from '../../icms-styled-components/IcmsStyledComponents'

export const AddBankProfile = (props) => {
    const { data, handleClose, partyId } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })

    const bankValidation = Yup.object().shape({
        accountNumber: Yup.string().required(t("Account_Number_Is_Required"))
            .min(11, t("Account_Number_Between_6_and_18_Characters"))
            .max(18, t("Account_Number_Cannot_Exceed_16_Characters")),
        accountHolderName: Yup.string().required(t("Account_Holder_Name_Is_Required")).matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, t('Invalid_Input')),
        ifsc: Yup.string().required(t("IFSC_Code_Is_Required")).matches(RegEx.IFSC, t('Invalid_IFSC_Code')),
    })

    const bankListFormik = useFormik({
        initialValues: {
            bankId: data.bankRow.bankId ? data.bankRow.bankId : null,
            partyId: data.bankRow.partyId ? data.bankRow.partyId : partyId,
            branchId: data.bankRow.branchId ? data.bankRow.branchId : null,
            branchName: data.bankRow.branchName ? data.bankRow.branchName : '',
            bankName: data.bankRow.bankName ? data.bankRow.bankName : '',
            bankAccountCd: data.bankRow.bankAccountCd ? data.bankRow.bankAccountCd : BankAccountTypeCd.SAVINGS_ACCOUNT.codeCd,
            accountNumber: data.bankRow.accountNumber ? data.bankRow.accountNumber : '',
            accountHolderName: data.bankRow.accountHolderName ? data.bankRow.accountHolderName : '',
            ifsc: data.bankRow.ifsc ? data.bankRow.ifsc : ''
        },
        onSubmit: (values) => {
            createBank(values)
        },
        validationSchema: bankValidation,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: true
    })

    const createBank = (values) => {
        setLoading(true)
        const payload = {
            ...values
        }
        const requestMethod = data.bankRow.partyId ? ICMSAxiosInterceptor.put : ICMSAxiosInterceptor.post;
        const url = data.bankRow.partyId ? `bank-account/${data.bankRow.bankAccountUuid}` : `bank-account`
        requestMethod(url, payload).then(() => {
            handleClose(true)
            props.setMessage({ show: true, message: data.bankRow.partyId ? t("Bank_Updated_Successfully") : t("Bank_Created_Successfully"), severity: 'success' });
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const clearForm = () => {
        bankListFormik.resetForm();
    };

    return (
        <Dialog open={data.open} maxWidth='lg' fullWidth>
            <DialogTitle data-testid='bank-title'>{data.bankRow.partyId ? t("Update_Bank") : t("Add_Bank")}</DialogTitle>
            <DialogContent dividers>
                <BankAccountFields bankFormik={bankListFormik} disabled={false} handleClear={clearForm} showIFSCSearch={!data.bankRow.partyId} errorMessage={setMessage} />
            </DialogContent>
            <DialogActions>
                <ICMSButton color='primary' size='small' data-testid='cancel' variant='outlined' onClick={() => handleClose(false)}>{t("Cancel")}</ICMSButton>
                <ICMSButton color='primary' size='small' data-testid='create-update' variant='contained' onClick={bankListFormik.handleSubmit}
                    >{data.bankRow.partyId ? t("Update") : t("Create")}</ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Dialog>
    )
}

