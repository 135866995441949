import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormikLabelTextField } from "../formik-fields-components/FormikFieldComponents"
import { SelectLabelController } from '../formik-fields-components/SelectLabelController';
import { SearchBankByIFSC } from "../../search-components/SearchBankByIFSC";
import { AccountTypes } from '../GenericCodes';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import ICMSLoader from '../icms-loader/ICMSLoader';
import { ICMSButton } from '../icms-styled-components/IcmsStyledComponents';
import { CreateBank } from '../../public-information/bank/CreateBank';

const BankAccountFields = ({ bankFormik, disabled, handleClear, showIFSCSearch, errorMessage }) => {
    const { t } = useTranslation();
    const [accountTypes, setAccountTypes] = useState([])
    const [loading, setLoading] = useState(false);
    const [showClearBtn, setShowClearBtn] = useState(false)
    const [openCreateBranch, setOpenCreateBranch] = useState({
        open: false,
        // bankRow: {},
        bank: true,
        branch: true
    });

    const handleSelectIFSC = (bank) => {
        if (bank === null) {
            bankFormik.setFieldValue('bankName', '')
            bankFormik.setFieldValue('branchName', '')
            bankFormik.setFieldValue('ifsc', '')
        } else {
            bankFormik.setValues(values => ({
                ...values,
                branchId: bank.partyId,
                ifsc: bank.identification,
                branchName: bank.name,
                bankName: bank.bankName
            }))
        }

        setShowClearBtn(true)
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        bankFormik.setFieldTouched(id, true);
        if (id === 'accountNumber') {
            const digitsOnly = value.replace(/[^\d]/g, ''); // Remove non-digit characters using [^\d]
            bankFormik.setFieldValue('accountNumber', digitsOnly.trimStart());
        } else {
            bankFormik.handleChange({
                target: {
                    id,
                    value: value.trimStart()
                },
            });
        }
    }

    const listBankAccount = () => {
        setLoading(true)
        ICMSAxiosInterceptor.get(`codes/bank_account`).then((response) => {
            setAccountTypes(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            errorMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    useEffect(() => {
        listBankAccount()
    }, [])

    const addBranchDetail = (row) => {
        setOpenCreateBranch({
            open: true,
            // bankRow: row ? row : {},
            bank: true,
            branch: true
        })
    }

    const handleCloseBranch = () => {
        setOpenCreateBranch({
            open: false
        })
    }

    return (
        <div>
            {showIFSCSearch && showClearBtn && <Button onClick={handleClear} variant='outlined' style={{ float: 'right' }}>{t("Clear")}</Button>}
            <Grid container spacing={2} my={1}>
                <Grid item xs={4}>
                    <FormikLabelTextField
                        id="accountHolderName"
                        fieldName="accountHolderName"
                        label={t("Account_Holder_Name")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        // handleChange={handleChange}
                        handleChange={bankFormik.handleChange}
                        handleBlur={bankFormik.handleBlur}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikLabelTextField
                        id="accountNumber"
                        fieldName="accountNumber"
                        label={t("Account_Number")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        handleChange={handleChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectLabelController
                        id="bankAccountCd"
                        fieldName="bankAccountCd"
                        label={t("Account_Type")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        menuItems={accountTypes}
                        menuFieldId={'codeCd'}
                        menuFieldTitle={'description'}
                        valueField={'codeCd'}
                        handleChange={bankFormik.handleChange}
                        handleBlur={bankFormik.handleBlur}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={4}>
                    {showIFSCSearch &&
                        <SearchBankByIFSC handleSelectIFSC={handleSelectIFSC} id='ifsc' required={true} label='IFSC' />
                    }
                </Grid>
                <Grid item xs={12}  md={4} alignSelf='center'>
                    {showIFSCSearch &&
                        <ICMSButton variant='contained' onClick={addBranchDetail}>create branch</ICMSButton>

                    }
                </Grid>
            </Grid>

            <Grid container spacing={2} my={1}>
                <Grid item xs={4}>
                    <FormikLabelTextField
                        id="bankName"
                        fieldName="bankName"
                        label={t("Bank_Name")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        disabled={true}
                        handleChange={bankFormik.handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikLabelTextField
                        id="branchName"
                        fieldName="branchName"
                        label={t("Branch_Name")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        disabled={true}
                        handleChange={bankFormik.handleChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikLabelTextField
                        id="ifsc"
                        fieldName="ifsc"
                        label={t("IFSC_Code")}
                        formik={bankFormik}
                        size="small"
                        required={true}
                        disabled={true}
                        handleChange={bankFormik.handleChange}
                    />
                </Grid>
            </Grid>
            {loading && <ICMSLoader show={loading} />}
            {openCreateBranch.open && <CreateBank data={openCreateBranch} handleClose={handleCloseBranch}/>}
        </div>
    )
}

export default BankAccountFields