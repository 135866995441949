import axios from "axios";
import { encrypt, decrypt } from './encrypt-decrypt';

const HttpStatus = {
    NOT_MODIFIED: 304
}

export const AuthAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const AxiosICMSInterceptor = axios.create({
    baseURL: process.env.REACT_APP_ICMS_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const PublicAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_ICMS_ENDPOINT
})

export const LookUpAxiosICMSInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_LOOK_UP_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const ReportAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_REPORT_ENDPOINT
})

export const ICMSAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_ICMS_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const ICMSAxiosDownloadInterceptor = axios.create({
    baseURL: process.env.REACT_APP_ICMS_API_ENDPOINT,
    responseType: 'arraybuffer'
})

export const ICMSUploadAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_ICMS_API_ENDPOINT
})

ICMSAxiosDownloadInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    // if (config.data) {
    //     config.data = encrypt(JSON.stringify(config.data));
    // }
    return config
}, (error) => {
    return Promise.reject(error)
})

ICMSAxiosDownloadInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let responseData = error.response?.data instanceof ArrayBuffer ? 
            (new TextDecoder().decode(new Uint8Array(error.response?.data)) != '' ? JSON.parse(new TextDecoder().decode(new Uint8Array(error.response?.data))) : '') : 
            (error.response?.data ? JSON.parse(error.response?.data) : { message: error.message || 'Try Again'});
    let originalRequest = error.config;
    if (error.response?.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return ICMSAxiosDownloadInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response?.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (responseData?.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData?.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

AuthAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

AuthAxiosInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    let responseData = error.response?.data ? decrypt(error.response?.data) : { message: error.message || 'Try Again'};
    if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

LookUpAxiosICMSInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

LookUpAxiosICMSInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    let responseData = error.response?.data ? decrypt(error.response?.data) : { message: error.message || 'Try Again'};
    // error.response.data = decrypt(error.response.data);
    if (error.config.url === 'token' && error.response?.status === 401) {
        return Promise.reject({ message: 'refresh-token-expired' })
    } else if (responseData.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

PublicAxiosInterceptor.interceptors.request.use((config) => {
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

PublicAxiosInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    let responseData = error.response?.data ? decrypt(error.response?.data) : { message: error.message || 'Try Again'};
    // error.response.data = decrypt(error.response.data);
    if (error.config.url === 'token' && error.response?.status === 401) {
        return Promise.reject({ message: 'refresh-token-expired' })
    } else if (responseData.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

ICMSAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

ICMSAxiosInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    let responseData = error.response?.data ? decrypt(error.response.data) : { message: error.message || 'Try Again' };
    let originalRequest = error.config;
    if (error.response?.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return ICMSAxiosInterceptor(originalRequest);
        }).catch(err => {
            if (err.message && err.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response?.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (responseData.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

ICMSUploadAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    // if (config.data) {
    //     config.data = encrypt(JSON.stringify(config.data));
    // }
    return config
}, (error) => {
    return Promise.reject(error)
})

ICMSUploadAxiosInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let responseData = error.response?.data ? error.response?.data : { message: error.message || 'Try Again'};
    let originalRequest = error.config;
    if (error.config.url === 'token' && error.response?.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return ICMSUploadAxiosInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response?.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (responseData.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})

ReportAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    // if (config.data) {
    //     config.data = encrypt(JSON.stringify(config.data));
    // }
    return config
}, (error) => {
    return Promise.reject(error)
})

ReportAxiosInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let responseData = error.response?.data ? error.response.data : { message: error.message || 'Try Again'};
    // error.response.data = decrypt(error.response.data);
    let originalRequest = error.config;
    if (error.response?.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            sessionStorage.setItem('accessTokenExpiry', Date.now() + response.expires_in * 1000);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return ReportAxiosInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response?.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (responseData.errorMessage) {
        return Promise.reject({ message: responseData.errorMessage });
    } else if (responseData.message) {
        return Promise.reject({ message: responseData.message });
    } else {
        return Promise.reject(error);
    }
})
