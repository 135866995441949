import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DialogDraggableComponent from '../../common/DialogDraggable/DialogDraggableComponent'
import { ICMSButton } from '../../common/icms-styled-components/IcmsStyledComponents';
import ICMSLoader from '../../common/icms-loader/ICMSLoader';
import IcmsSnackbar from '../../common/icms-snackbar/IcmsSnackbar';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikLabelMuiPhone, FormikLabelTextField, FormikMuiPhoneNumber } from '../../common/formik-fields-components/FormikFieldComponents';
import * as Yup from 'yup';
import { FormikSelectController } from '../../common/formik-fields-components/FormikSelectController';
import CloseIcon from '@mui/icons-material/Close';
import { SelectLabelController } from '../../common/formik-fields-components/SelectLabelController';
import { AddressTypes, DefaultCountry, IdentificationType, PartyType, RegEx } from '../../common/GenericCodes';
import { TextAreaLabelController } from '../../common/formik-fields-components/TextAreaLabelController';
import AddressDetail from '../../icms/address/AddressDetails';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { ICMSAxiosInterceptor } from '../../config/axios.interceptor';
import { setFormikValueWithLowerCase } from '../../common/utils/Utils';
import _ from 'lodash';

export const CreateBank = (props) => {
    const { data, handleClose, bankPartyId } = props;
    const [loading, setLoading] = useState(false);
    const [bankListData, setBankListData] = useState([]);
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [isModified, setIsModified] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    const identificationValidation = Yup.string()
        .required('Identification is required')
        .test('is-valid-identification', 'Invalid Identification', function (value) {
            const regex = data.branch ? RegEx.IFSC : RegEx.REFERENCE_NUMBER;
            return regex.test(value);
        });

    const bankValidation = Yup.object().shape({
        name: Yup.string().required(t('Name_Is_Required')).matches(RegEx.USERNAME, t('Invalid_Input')),
        // email: Yup.string().required(t('Email_Is_Required')).matches(RegEx.EMAIL, 'Invalid Email'),
        // phoneNumber: Yup.string().required(t("Mobile_Number_Is_Required"))
        //     .test("phoneNumber", t("Mobile_Number_Is_Not_Valid"), (str, context) => {
        //         const pn = isValidPhoneNumber(context.parent['extension'] + str);
        //         return pn;
        //     }),
        identification: identificationValidation,
        cityOrTown: Yup.string().required(t("City_Town_Is_Required")),
        country: Yup.string().required(t("Country_Is_Required")),
        state: Yup.string().required(t("State_Is_Required")),
        postalCode: Yup.string()
            .matches(RegEx.PIN_CODE, t('Invalid_Pin_Code'))
            .required(t("Pin_Code_Is_Required")),
        addressLine1: Yup.string().required(t("Address_Line1_Is_Required")),
        phoneNumber: Yup.string().nullable().test(
            "phoneNumber",
            "Invalid Mobile Number",
            function (value) {
                const { extension } = this.parent;
                if (value) {
                    return isValidPhoneNumber(extension + value);
                }
                return true;
            }
        ),
        email: Yup.string().nullable().matches(RegEx.EMAIL, 'Invalid Email'),
    });

    const bankFormik = useFormik({
        initialValues: {
            partyCd: data.bankRow?.partyCd || PartyType.BANK,
            identificationCd: IdentificationType.CIN,
            name: data.bankRow?.name || null,
            email: data.bankRow?.email || null,
            phoneNumber: data.bankRow?.phoneNumber || null,
            extension: "+91",
            identification: data.bankRow?.identification || '',
            description: data.bankRow?.description || '',
            addressLine1: data.bankRow?.addressLine1 || '',
            addressLine2: data.bankRow?.addressLine2 || '',
            cityOrTown: data.bankRow?.cityOrTown || '',
            state: data.bankRow?.state || '',
            postalCode: data.bankRow?.postalCode || '',
            country: data.bankRow?.country || DefaultCountry.COUNTRY_NAME,
            addressCd: data.bankRow?.addressCd || AddressTypes.registerdWithIBBI.addressCd,
            bankId: bankPartyId ? bankPartyId : null
        },
        onSubmit: (values) => {
            if (data.branch) {
                if (data.bankRow?.partyId) { // change this while updating branch...
                    updateBranch(values)
                } else {
                    createBranch(values)
                }
            } else {
                if (data.bankRow?.partyId) {
                    updateBank(values)
                } else {
                    createBank(values)
                }
            }
        },
        validationSchema: bankValidation,
        validateOnChange: true,
        validateOnBlur: true
    })

    const createBank = (values) => {
        setLoading(true)
        const payload = { ...values }
        ICMSAxiosInterceptor.post(`bank`, payload).then((response) => {
            handleClose(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const updateBank = (values) => {
        setLoading(true)
        const payload = { ...values }
        ICMSAxiosInterceptor.put(`bank/${data.bankRow?.partyId}`, payload).then((response) => {
            handleClose(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const createBranch = (values) => {
        setLoading(true)
        const payload = { ...values }
        ICMSAxiosInterceptor.post(`bank/${bankFormik.values.bankId}/branch`, payload).then((response) => {
            handleClose(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const updateBranch = (values) => {
        setLoading(true)
        const payload = { ...values }
        ICMSAxiosInterceptor.put(`bank/${bankFormik.values.bankId}/branch/${data.bankRow?.partyId}`, payload).then((response) => {
            handleClose(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    useEffect(() => {
        if (data.bank) {
            bankList()
        }
        if (data.bankRow?.partyId) {
            setLoading(true)
            const url = data.branch ? `bank/${bankFormik.values.bankId}/branch/${data.bankRow.partyId}` : `bank/${data.bankRow.partyId}`
            ICMSAxiosInterceptor.get(url).then((response) => {
                if (response) {
                    const fetchedValues = {
                        partyCd: response.partyCd || PartyType.BANK,
                        identificationCd: response.identificationCd || IdentificationType.CIN,
                        name: response.name || null,
                        email: response.email || null,
                        phoneNumber: response.phoneNumber || null,
                        extension: response.extension || "+91",
                        identification: response.identification || '',
                        description: response.description || '',
                        addressLine1: response.addressLine1 || '',
                        addressLine2: response.addressLine2 || '',
                        cityOrTown: response.cityOrTown || '',
                        state: response.state || '',
                        postalCode: response.postalCode || '',
                        country: response.country || DefaultCountry.COUNTRY_NAME,
                        addressCd: response.addressCd || AddressTypes.registerdWithIBBI.addressCd,
                        bankId: bankPartyId
                    };
                    setInitialValues(fetchedValues)
                    bankFormik.setValues(fetchedValues)
                }
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
        }
    }, [data.bankRow?.partyId])

    const bankList = () => {
        setLoading(true)
        ICMSAxiosInterceptor.get(`bank`, {}).then((response) => {
            setBankListData(response.items)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    const handleChangeIdentification = (event) => {
        const { id, value } = event.target;
        bankFormik.setFieldValue('identification', value.toUpperCase().trimStart())
    }

    useEffect(() => {
        const checkIfModified = () => {
            setIsModified(!_.isEqual(bankFormik.values, initialValues));
        };

        checkIfModified();
    }, [bankFormik.values, initialValues]);

    return (
        <Dialog open={data.open} fullWidth maxWidth='md' data-testid='add-contribution-dialog'
            PaperComponent={DialogDraggableComponent}
            aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography data-testid='contribution-title' variant='h6'>{data.branch ? t("Add_Branch") : t("Add_Bank")}</Typography>
                    <IconButton onClick={() => handleClose(false)} color='error' data-testid='close-contribution'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    {data.bank && <Grid item xs={12} sm={12} md={4} lg={4}>
                        <SelectLabelController
                            id="bankId"
                            fieldName="bankId"
                            label={t("Select_Bank")}
                            formik={bankFormik}
                            size="small"
                            required={true}
                            menuItems={bankListData}
                            menuFieldId={'partyId'}
                            menuFieldTitle={'name'}
                            valueField={'partyId'}
                            handleChange={bankFormik.handleChange}
                            handleBlur={bankFormik.handleBlur}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormikLabelTextField
                            id='name'
                            fieldName='name'
                            label={data.branch ? t("Name_Of_Branch") : t("Name_Of_Bank")}
                            formik={bankFormik}
                            size='small'
                            handleChange={bankFormik.handleChange}
                            required={true}
                            handleBlur={bankFormik.handleBlur}
                        />
                    </Grid>
                    {!(data.bankRow?.partyId) && <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormikLabelTextField
                            id="email"
                            fieldName="email"
                            label={t("Email")}
                            size='small'
                            formik={bankFormik}
                            // required={true}
                            handleChange={(event) => setFormikValueWithLowerCase(event, bankFormik)}
                            handleBlur={bankFormik.handleBlur}
                        />
                    </Grid>}
                    {!(data.bankRow?.partyId) && <Grid item xs={12} md={4} sm={4}>
                        <FormikLabelMuiPhone
                            id="phoneNumber"
                            fieldName="phoneNumber"
                            label={t("Mobile_Number")}
                            countryCodeName="extension"
                            size='small'
                            formik={bankFormik}
                            // required={true}
                            handleChange={bankFormik.handleChange}
                            handleBlur={bankFormik.handleBlur}
                        />
                    </Grid>}
                    <Grid item xs={12} lg={4} md={4} sm={12}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={data.branch ? t("Identification(IFSC)") : t("Identification(CIN)")}
                            size='small'
                            formik={bankFormik}
                            handleChange={handleChangeIdentification}
                            required={true}
                            handleBlur={bankFormik.handleBlur}
                        />
                    </Grid>
                    {!data.branch && <Grid item xs={12} md={6} sm={6}>
                        <TextAreaLabelController
                            id="description"
                            fieldName="description"
                            label={t("Description")}
                            formik={bankFormik}
                            size="small"
                            minRows={2}
                            handleChange={bankFormik.handleChange}
                        />
                    </Grid>}
                    <Grid item xs={12} md={12} sm={12}>
                        <AddressDetail addressFormik={bankFormik} required={true} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ cursor: 'move' }} id="draggable-dialog-title">
                <ICMSButton size='small' variant='outlined' onClick={() => handleClose(false)} data-testid='contribution-cancel'>{t("Cancel")}</ICMSButton>
                <ICMSButton size='small' variant='contained' onClick={bankFormik.handleSubmit} disabled={!isModified || !bankFormik.isValid}
                    data-testid='contribution-save-update'>{t('Save')}</ICMSButton>
            </DialogActions>
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
        </Dialog>
    )
}
