import React, {
    useState, useEffect, Fragment, useContext
}                                               from 'react';
import { 
    Stack, Button, Accordion,
    AccordionSummary, Typography,
    AccordionDetails,
    ButtonGroup
}                                               from '@mui/material';
import { Add }                                  from "@mui/icons-material";
import { useTranslation }                       from 'react-i18next';
import { FinancialClaimView }                   from '../../file-claim/fc-file-claim/claim-list/fc-claim/FinancialClaimView';
import { FinancialClaimDetail }                 from '../../file-claim/fc-file-claim/claim-list/fc-claim/FinancialClaimDetail';
import { EmployeeWorkmanClaim }                 from '../../file-claim/fc-file-claim/claim-list/emp-work-claim/EmployeeWorkmanClaim';
import { EmployeeWorkmanClaimView }             from '../../file-claim/fc-file-claim/claim-list/emp-work-claim/EmployeeWorkmanClaimView';
import { FCHomebuyerClaim }                     from '../../file-claim/fc-file-claim/claim-list/fc-homebuyer-claim/FCHomebuyerClaim';
import { FCHomebuyerClaimView }                 from '../../file-claim/fc-file-claim/claim-list/fc-homebuyer-claim/FCHomebuyerClaimView';
import { SupplierClaim }                        from '../../file-claim/fc-file-claim/claim-list/supplier-claim/SupplierClaim';
import { SupplierClaimView }                    from '../../file-claim/fc-file-claim/claim-list/supplier-claim/SupplierClaimView';
import { OtherCreditorStakeClaim }              from '../../file-claim/fc-file-claim/claim-list/other-creditor-stake-claim/OtherCreditorStakeClaim';
import { OtherCreditorStakeView }               from '../../file-claim/fc-file-claim/claim-list/other-creditor-stake-claim/OtherCreditorStakeView';
import { ICMSAxiosInterceptor }                 from '../../../config/axios.interceptor';
import { CaseType, ClaimType, RelationshipType }from '../../../common/GenericCodes';
import IcmsSnackbar                             from '../../../common/icms-snackbar/IcmsSnackbar';
import ICMSLoader                               from '../../../common/icms-loader/ICMSLoader';
import ICMSConfirmDialog                        from '../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { deleteClaim }                          from "../../file-claim/file-claim-services/ClaimService";
import ClaimAdmission                           from '../../file-claim/fc-file-claim/claim-list/claim-admission/ClaimAdmission';
import { ICMSButton }                           from '../../../common/icms-styled-components/IcmsStyledComponents';
import { CaseContext }                          from '../../../common/context/case/CaseContext';

const CreditorClaim = ({claimCd, caseId, creditorId, homeBuyer, relationShipCd, caseCd, getCreditor, 
        claimSubmission, canEditClaim, canEditBook, canEditAdmitted, canViewBook, canEdit, caseStage, 
        setEnabledSteps, insolvencementDate}) => {
    const { t } = useTranslation();
    const [claims, setClaims] = useState([]);
    const [isNew, setIsNew] = useState(false);
    const [claimDetail, setClaimDetail] = useState(null);
    const [activePanel, setActivePanel] = useState({});
    const [loading, setLoading] = useState(false);
    const [claimMinDate, setClaimMinDate] = useState(null);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [showClaimAdmission, setShowClaimAdmission] = useState({
        show: false,
        claim: null
    });
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })
    const { contextValue } = useContext(CaseContext);

    const handleClaimAdmission = (event, claimDetails) => {
        event.stopPropagation();
        setShowClaimAdmission({
            show: true,
            claim: claimDetails
        });
    }

    const handleCancelClaimAdmission = (event) => {
        setShowClaimAdmission({
            show: false,
            claim: null
        });
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        })
    }

    const getClaims = () => {
        let payload = {}
        if (claimCd) {
            payload = {
                claimCds: claimCd.length ? [...claimCd] : [claimCd]
            }
        } 
        setActivePanel({});
        setClaims([]);
        ICMSAxiosInterceptor.post(`case/${caseId}/claimant/${creditorId}/claim/list`, payload)
                .then(response => {
            if (setEnabledSteps) {
                setEnabledSteps(prevState => ({
                    ...prevState,
                    claimDetails: response.items.length > 0
                }))
            }
            if (response.items.length > 0) {
                setClaims(response.items);
            } else {
                setIsNew(true);
            }
        }).catch(error => {
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
    }

    const handleChange = (index, claim) => (event, isExpanded) => {
        setActivePanel(prevState => ({
            ...prevState,
            ['panel' + index]: isExpanded,
        }));
        setClaimDetail(claim);
    };

    const closeCurrentPanel = (index) => {
        if (index !== undefined) {
           setActivePanel(prevState => ({
               ...prevState,
               ['panel' + (index + 1)]: false,
           }));
        }
        getClaims();
        setIsNew(false);
    }

    const handleRefresh = (msg) => {
        if(msg) setMessage(msg);
        getClaims();
        if(getCreditor) getCreditor();
    }

    const deleteConfirmation = (event, debtDefaultId, index) => {
        event.stopPropagation();
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Claim') } `,
            onDisAgree: resetSeekConfirmation,
            onAgree: () => {
                handleDelete(debtDefaultId);
                resetSeekConfirmation();
            },
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }


    const handleDelete = (debtDefaultId) => {
        setLoading(true);
        deleteClaim(caseId, creditorId, debtDefaultId,
            (response) => {
                handleRefresh({ show: true, message: t('Claim_Deleted_Sucessfully'), severity: 'success' });
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            })
    }

    useEffect(() => {
        getClaims();
    }, [])

    useEffect(() => {
        if (caseCd == CaseType.CIRP || caseCd == CaseType.FTIRP) {
            setClaimMinDate(contextValue?.insolvencyCommencedOn ? new Date(contextValue?.insolvencyCommencedOn) : insolvencementDate);
        } else if (caseCd == CaseType.LIQUIDATION || caseCd == CaseType.VAL_LIQUIDATION) {
            setClaimMinDate(contextValue?.liquidationCommencedOn ? new Date(contextValue?.liquidationCommencedOn) : insolvencementDate);
        }
    }, [])
    
    return (
        <div>
            {<Stack>
                    {canEdit && <Stack justifyContent='end' alignItems='end' p={1}>
                        <ICMSButton sx={{ width: 'fit-content' }} color="primary" variant="contained" startIcon={<Add />} disabled={isNew} size="small"
                            data-testid="add-claim" onClick={() => setIsNew(true)}>{t('Add_Claim')}</ICMSButton>
                    </Stack>}
                    {isNew &&
                        <Accordion expanded={isNew} onChange={() => { setIsNew(false) }}
                            sx={{
                                mb: '1rem',
                                border: '1px solid #eeeeee',
                                boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                                borderRadius: '12px !important',
                                "&::before": {
                                    backgroundColor: 'transparent !important'
                                }
                            }}>
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                    <Typography variant="h6">{t('Add_New_Claim')}</Typography>
                                    {/* <ButtonGroup variant="outlined" aria-label="claimant-details" size="small">
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('due')}
                                            >{t('Due_Details')}</ICMSButton>
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('principal-borrower')}
                                            >{t('Principal_Borrower')}</ICMSButton>
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('beneficiary')}
                                            >{t('Beneficiary_Details')}</ICMSButton>
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('guarantee')}
                                            >{t('Guarantee')}</ICMSButton>
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('charge')}
                                            >{t('Security_Interest')}</ICMSButton>
                                        <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('adjudication')}
                                            >{t('Adjudication')}</ICMSButton>
                                        {!claimSubmission && <ICMSButton color='primary' 
                                            variant={"outlined"}
                                            // onClick={() => onSelectedInfoChange('distribution')}
                                            >{t('Distribution')}</ICMSButton>}
                                    </ButtonGroup> */}
                            </AccordionSummary>
                            <AccordionDetails>
                                {<Fragment>
                                    {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && !homeBuyer) && <FinancialClaimDetail claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                    {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && homeBuyer) && <FCHomebuyerClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage} claimCd={claimCd}
                                            relationShipCd={relationShipCd}/>}
                                    {RelationshipType.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            claimCd={claimCd} canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                    {RelationshipType.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            claimCd={claimCd} canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                    {RelationshipType.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            claimCd={claimCd} canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                    {RelationshipType.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            claimCd={claimCd} canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                    {RelationshipType.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={null} 
                                            listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} caseId={caseId} claimantId={creditorId}
                                            claimCd={claimCd} canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} caseCd={caseCd} canViewBook={canViewBook}
                                            claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                            relationShipCd={relationShipCd}/>}
                                </Fragment>}
                            </AccordionDetails>
                        </Accordion>
                    }
                    {claims.length > 0 && claims.map((claim, index) => {
                        return (
                            <Accordion key={index} expanded={activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true} onChange={handleChange(index + 1, claim)}
                                sx={{
                                    mb: '1rem',
                                    border: '1px solid #eeeeee',
                                    boxShadow: '0px 20px 35px 0px rgba(15.000000000000002, 33.000000000000014, 60.00000000000001, 0.13)',
                                    borderRadius: '12px !important',
                                    "&::before": {
                                        backgroundColor: 'transparent !important'
                                    }
                                }}>
                                <AccordionSummary
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    {activePanel['panel' + (index + 1)] !== undefined && activePanel['panel' + (index + 1)] === true ?
                                        <Typography variant="h6">{index + 1}.&nbsp;Edit Claim&nbsp;-&nbsp;{claim.debtId}</Typography>
                                        :
                                        <Fragment>
                                            {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && claim.claimCd !== ClaimType.RES_HOME_BUYER && 
                                                        claim.claimCd !== ClaimType.LIQ_HOME_BUYER) 
                                                    && <FinancialClaimView claim={claim} handleDelete={deleteConfirmation} index={index}
                                                            canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && (claim.claimCd === ClaimType.RES_HOME_BUYER ||
                                                        claim.claimCd === ClaimType.LIQ_HOME_BUYER)) 
                                                    && <FCHomebuyerClaimView claim={claim} handleDelete={deleteConfirmation} index={index} canViewBook={canViewBook}
                                                            onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {RelationshipType.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaimView claim={claim} handleDelete={deleteConfirmation} 
                                                    index={index} canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {RelationshipType.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaimView claim={claim} handleDelete={deleteConfirmation} 
                                                    index={index} canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {RelationshipType.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaimView claim={claim} handleDelete={deleteConfirmation} 
                                                    index={index} canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {RelationshipType.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeView claim={claim} handleDelete={deleteConfirmation} 
                                                    index={index} canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                            {RelationshipType.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeView claim={claim} handleDelete={deleteConfirmation} 
                                                    index={index} canViewBook={canViewBook} onClickAdmission={handleClaimAdmission} canEdit={canEdit}/>}
                                        </Fragment>
                                    }
                                </AccordionSummary>
                                <AccordionDetails>
                                    {claimDetail !== null && <Fragment>
                                        {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && !homeBuyer) && <FinancialClaimDetail claimDetail={claim} listForClaim={handleRefresh} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {(RelationshipType.FINANCIAL_CLAIMANT === relationShipCd && homeBuyer) && <FCHomebuyerClaim claimDetail={claim} 
                                                listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {RelationshipType.SUPPLIER_CLAIMANT === relationShipCd && <SupplierClaim claimDetail={claim} listForClaim={handleRefresh} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {RelationshipType.EMPLOYEE_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={claim} listForClaim={handleRefresh} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {RelationshipType.WORKMAN_CLAIMANT === relationShipCd && <EmployeeWorkmanClaim claimDetail={claim} listForClaim={handleRefresh} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {RelationshipType.OTHER_CREDITOR_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={claim} listForClaim={handleRefresh} 
                                                closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                        {RelationshipType.OTHER_STAKEHOLDER_CLAIMANT === relationShipCd && <OtherCreditorStakeClaim claimDetail={claim} 
                                                listForClaim={handleRefresh} closeCurrentPanel={closeCurrentPanel} index={index} caseId={caseId} claimantId={creditorId}
                                                canEditClaim={canEditClaim} canEditBook={canEditBook} canEditAdmitted={canEditAdmitted} canViewBook={canViewBook} caseCd={caseCd}
                                                claimSubmission={claimSubmission} canEdit={canEdit} claimMinDate={claimMinDate} caseStage={caseStage}
                                                relationShipCd={relationShipCd}/>}
                                    </Fragment>}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Stack>
            }
            {loading && <ICMSLoader show={loading}/>}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity} 
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/>}
            { seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}
            /> }
            {showClaimAdmission.show && <ClaimAdmission show={showClaimAdmission.show} onClose={handleCancelClaimAdmission}
                claimDetails={showClaimAdmission.claim} claimantId={creditorId} caseId={caseId}
                onSuccess={handleRefresh} claimMinDate={claimMinDate}/>}
        </div>
    )
}

export default CreditorClaim