import React, {
    useState, useEffect, Fragment, useContext 
}                                       from "react";
import { Grid, Stack, Typography,
    ButtonGroup, FormLabel, Checkbox, FormControlLabel, Tooltip }            from "@mui/material";
import { useParams }                    from "react-router-dom";
import { useTranslation }               from "react-i18next";
import { FaHandshakeSimple }            from "react-icons/fa6"
import { GiPayMoney }                   from "react-icons/gi"
import { 
    CreditorsType, FinancialCreditorTypes, 
    IdentificationByPartyType 
}                                       from "../../../../common/StaticVariables";
import {  
    FormikLabelMuiPhone, 
    FormikLabelTextField 
}                                       from "../../../../common/formik-fields-components/FormikFieldComponents";
import { SelectLabelController }        from '../../../../common/formik-fields-components/SelectLabelController';
import YesNoSwitchController            from "../../../../common/formik-fields-components/yes-no-switch-controller/YesNoSwitchController";
import { StepperSectionHeader }         from "../../../../common/icms-styled-components/StepperSectionHeader";
import AddressDetail                    from "../../../address/AddressDetails";
import FCSearch                         from "../../fc-search/FCSearch";
import { RelationshipCd }               from "../FileClaimGenericCodes";
import EmailList                        from '../../../../common/email/EmailList';
import PhoneNumberList                  from '../../../../common/phone-number/PhoneNumberList';
import IdenticationList                 from "../../../../common/identification/IdentificationList";
import { FormikNewDateTime }            from '../../../../common/formik-fields-components/FormikNewDateTime';
import { FormikLabelNumericFormat }     from '../../../../common/formik-fields-components/FormikLabelNumericFormat';
import { listClassOfCreditor }          from "../../file-claim-services/FileClaimServices";
import { StyledCard, ICMSButton }       from "../../../../common/icms-styled-components/IcmsStyledComponents";
import { TextAreaLabelController }      from '../../../../common/formik-fields-components/TextAreaLabelController';
import { setFormikValueWithLowerCase, 
    setFormikValueWithUpperCase }       from '../../../../common/utils/Utils';
import { AddressTypes, CaseRoles, IdentificationType, PartyType, Stage }             from "../../../../common/GenericCodes";
import { ICMSAxiosInterceptor }         from "../../../../config/axios.interceptor";
import ICMSConfirmDialog                from '../../../../common/ConfirmationDialog/ICMSConfirmDialog';
import { UserContext } from "../../../../common/context/UserContext";

export const CreditorDetails = ({creditorFormik, relationshipType, claimSubmission, caseStage, isHomeBuyer, showSelf}) => {
    const { t } = useTranslation();
    const param = useParams();
    const loginDetails = useContext(UserContext).loginDetails;
    const [classOfCreditor, setClassOfCreditor] = useState([]);
    const [classAuthReps, setClassAuthReps] = useState([]);
    const [searchPartyDetails, setSearchPartyDetails] = useState({});
    const relationshipCd = relationshipType || Number(param.cd);
    const [loading, setLoading] = useState(false);
    const [claimingAsSelf, setClaimingAsSelf] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', severity: ''});
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: t('Yes'),
        disAgreeBtnLabel: t('No'),
        onDisAgree: ''
    })

    const getClassOfCreditors = () => {
        setLoading(true);
        listClassOfCreditor(Number(param.caseId), (response) => {
                setClassOfCreditor(response.items)
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setMessage({ show: true, message: error.message, severity: 'error' });
            }
        )
    }

    const onSelectSubmit = (creditor) => {
        let identification;
        let address = creditor.addressResources.find(add => add.addressCd == AddressTypes.registerdWithIBBI.addressCd);
        if (creditor.partyCd == PartyType.INDIVIDUAL) {
            identification = creditor.identifications.find(id => id.identificationCd == IdentificationType.PAN);
        } else {
            identification = creditor.identifications[0];
        }
        setSearchPartyDetails({
            ...creditor,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || '', 
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            identification: identification?.identification || ''
        });
        creditorFormik.setValues({
            claimantId: null,
            partyId: creditor.partyId,
            relationshipCd: relationshipCd,
            partyCd: creditor.partyCd,
            identificationId: identification?.identificationId || '',
            identificationCd: identification?.identificationCd || '',
            name: creditor.name,
            restOfName: creditor.restOfName,
            identification: identification?.identification || '',
            email: creditor.recentEmail,
            phoneNumber: creditor.recentPhoneDetail?.nationalNumber,
            extension: creditor.recentPhoneDetail?.countryCode ? '+' + creditor.recentPhoneDetail?.countryCode : '+91',
            dobDoi: creditor.dobDoi,
            secured: null,
            realtedParty: null,
            relatedUnderSection: null,
            amountOwedToDebtor: 0,
            contigent: 0,
            mutualDuesSetOff: 0,
            addressId: address?.addressId || '',
            addressCd: address?.addressCd || AddressTypes.registerdWithIBBI.addressCd, 
            addressLine1: address?.addressLine1 || '',
            addressLine2: address?.addressLine2 || '',
            cityOrTown: address?.cityOrTown || '',
            state: address?.state || '',
            postalCode: address?.postalCode || '',
            country: address?.country || 'India',
            serialNumber: null,
            retentionOfTitleArrangement: '',
            reconciliationAmount: 0,
            creditorClasses: null,
            regular: true,
            preferredAuthorizedRepresentativeId: '',
            preferredAuthorizedRepresentative: '',
            voteRight: '',
            loginUid: creditor.partyUuid,
            coPartners: '',
            coPartnersClaim: 0,
            coPartnersConsideration: ''
        });
    }

    const getPartyLocale = (partyCd) => {
        let title = '';
        FinancialCreditorTypes.forEach((item) => {
            if (partyCd === item.id) {
                title = item.partyLocale;
            }
        })
        return title;
    }

    const getIDLocale = (partyCd, value) => {
        let title = '';
        let identificationList = IdentificationByPartyType[partyCd];
        identificationList.forEach((item, index) => {
            if (value === item.id) {
                title = item.title;
            }
        })
        return title;
    }

    const resetForm = () => {
        creditorFormik.resetForm();
        creditorFormik.validateForm();
    }

    useEffect(() => {
        creditorFormik.setFieldValue('identificationCd', IdentificationByPartyType[creditorFormik.values.partyCd]?.[0].id)
        return () => {}
    }, [creditorFormik.values.partyCd])

    const handleEmployeeTypeChange = (type) => {
        creditorFormik.setFieldTouched('regular', true);
        creditorFormik.setFieldValue('regular', type)
    }
    
    const handelClassOfCreditorChange = (selectedClass) => {
        creditorFormik.setFieldValue('creditorClasses', selectedClass);
        let coc = classOfCreditor.find(coc => coc.name === selectedClass);
        if (coc) setClassAuthReps(coc.authorizedRepresentativeCreditorClass);
    }

    const getPartyDetailsByID = (identification) => {
        let payload = {
            identification: identification
        }
        ICMSAxiosInterceptor.post(`search/party`, payload).then(response => {
            if (response) {
                setSeekConfirmation(prevState => ({
                    ...prevState,
                    show: true,
                    title: t("Confirmation"),
                    message: `${t('Party_Details')} ${t('With').toLocaleLowerCase()} ${identification} 
                        ${t('Already_Exists').toLocaleLowerCase()}. ${t('Please_Use_The_Same_Details')}`,
                    onAgree: () => {
                        resetSeekConfirmation();
                        setClaimingAsSelf(false);
                        onSelectSubmit(response);
                    },
                    agreeBtnLabel: t('Ok'),
                }))
            }
        }).catch(error => {

        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('No')
        }))
    }

    useEffect(() => {
        getClassOfCreditors();
    }, [])

    useEffect(() => {
        if (claimingAsSelf) {
            setLoading(true);
            ICMSAxiosInterceptor.get(`/search/party/${loginDetails.partyId}`).then((response) => {
                if(response) {
                    onSelectSubmit(response);
                }
                setLoading(false);
            }).catch((error) => {
                setMessage({ show: true, message: error.message, severity: 'error' });
                setLoading(false);
            })
        } else {
            resetForm();
        }
    }, [claimingAsSelf])

    return (
        <Stack width="100%" spacing={2}>
            <Stack direction="row" justifyContent='center' alignItems='center' spacing={2} my={2}>
                {showSelf && <Tooltip title={t('Claiming_As_Self_Helper_Text')}>
                    <FormControlLabel control={<Checkbox checked={claimingAsSelf} />} label={t('Submit_Claim_As_Self')} 
                            onChange={(event) => setClaimingAsSelf(event.target.checked)}
                            sx={{border: `1px solid ${claimingAsSelf ? '#005588' : 'initial'}`, color: '#005588', 
                                    padding: '0px 8px', borderRadius: '24px'}}/>
                </Tooltip>}
                {!claimingAsSelf && <FCSearch placeholder={t('Search')} width={'40%'} isOnlyIndividual={RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd 
                    || RelationshipCd.WORKMAN_CLAIMANT === relationshipCd} onSelectSubmit={onSelectSubmit}
                    onClear={resetForm}/>}
            </Stack>
            <Stack>
                <Grid container spacing={2}>
                    {RelationshipCd.EMPLOYEE_CLAIMANT !== relationshipCd && RelationshipCd.WORKMAN_CLAIMANT !== relationshipCd &&
                        <Grid item xs={3}>
                            <SelectLabelController
                                id="partyCd"
                                fieldName="partyCd"
                                label={t("Institution_Type")}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                menuItems={FinancialCreditorTypes}
                                handleChange={(event) => {creditorFormik.handleChange(event)}}
                                menuFieldId={'id'}
                                menuFieldTitle={'title'}
                                valueField={'id'}
                                disabled={creditorFormik.values.partyId}
                            />
                        </Grid>
                    }
                    {(RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || RelationshipCd.WORKMAN_CLAIMANT === relationshipCd) &&
                        <Grid item xs={3}>
                            <FormikLabelTextField
                                id="serialNumber"
                                fieldName="serialNumber"
                                label={RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd ? t('Employee_ID') : t('Workman_ID')}
                                formik={creditorFormik}
                                size="small"
                                required={true}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                            />
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <FormikLabelTextField
                            id="name"
                            fieldName="name"
                            label={CreditorsType.INDIVIDUAL === creditorFormik.values.partyCd ? t('Name') : 
                                getPartyLocale(creditorFormik.values.partyCd) + ' ' + t('Name')}
                            formik={creditorFormik}
                            size="small"
                            required={true}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                            disabled={creditorFormik.values.partyId && searchPartyDetails?.name}
                        />
                    </Grid>
                    {/* {(RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || RelationshipCd.WORKMAN_CLAIMANT === relationshipCd) &&
                        <Grid item xs={2}>
                            <FormikNewDateTime
                                id='dobDoi'
                                fieldName='dobDoi'
                                formik={creditorFormik}
                                maxDate={(new Date())}
                                label={t('Appointment_Date')}
                                required={RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || RelationshipCd.WORKMAN_CLAIMANT === relationshipCd}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                size='small'
                                onlyDate={true}
                            />
                        </Grid>} */}
                    {(RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd || RelationshipCd.WORKMAN_CLAIMANT === relationshipCd) &&
                        <Grid item xs={2}>
                            <FormLabel sx={{ fontSize: (theme) => theme.typography.body2 }}>
                                {RelationshipCd.EMPLOYEE_CLAIMANT === relationshipCd && <Fragment>{t('Employee_Type')}</Fragment>}
                                {RelationshipCd.WORKMAN_CLAIMANT === relationshipCd && <Fragment>{t('Workman_Type')}</Fragment>}
                            </FormLabel>
                            <ButtonGroup variant="outlined" aria-label="employee-type" size="small">
                                <ICMSButton color='primary' 
                                    variant={creditorFormik.values.regular ? "contained" : "outlined"}
                                    onClick={() => handleEmployeeTypeChange(true)}>{t('Regular')}</ICMSButton>
                                <ICMSButton color='primary' 
                                    variant={!creditorFormik.values.regular ? "contained" : "outlined"}
                                    onClick={() => handleEmployeeTypeChange(false)}>{t('Casual')}</ICMSButton>
                            </ButtonGroup>
                    </Grid>}
                    {(CreditorsType.BANK !== creditorFormik.values.partyCd && CreditorsType.PROPBBUSINESS !== 
                            creditorFormik.values.partyCd && creditorFormik.values.claimantId === null) && IdentificationByPartyType[creditorFormik.values.partyCd].length > 1 
                            && <Grid item xs={2}>
                        <SelectLabelController
                            id="identificationCd"
                            fieldName="identificationCd"
                            label={t("Identification_Type")}
                            formik={creditorFormik}
                            size="small"
                            required={true}
                            menuItems={IdentificationByPartyType[creditorFormik.values.partyCd]}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                            menuFieldId={'id'}
                            menuFieldTitle={'title'}
                            valueField={'id'}
                            disabled={creditorFormik.values.partyId && searchPartyDetails?.identificationId}
                        />
                    </Grid>}
                    {creditorFormik.values.claimantId === null && <Grid item xs={3}>
                        <FormikLabelTextField
                            id="identification"
                            fieldName="identification"
                            label={`${t('Identification')} ${getIDLocale(creditorFormik.values.partyCd, 
                                creditorFormik.values.identificationCd) ? '(' + (getIDLocale(creditorFormik.values.partyCd, 
                                    creditorFormik.values.identificationCd)) + ')' : ''}`}
                            formik={creditorFormik}
                            size="small"
                            required={true}
                            handleChange={(event) => setFormikValueWithUpperCase(event, creditorFormik)}
                            handleBlur={(event) => {creditorFormik.handleBlur(event); getPartyDetailsByID(creditorFormik.values.identification)}}
                            disabled={creditorFormik.values.partyId && searchPartyDetails?.identificationId}
                            customStyle={{textTransform: 'uppercase'}}
                        />
                    </Grid>}
                    {creditorFormik.values.partyCd !== CreditorsType.INDIVIDUAL &&  creditorFormik.values.partyCd !== CreditorsType.BANK &&
                        <Grid item xs={CreditorsType.PROPBBUSINESS === creditorFormik.values.partyCd ? 2 : 3}>
                            <FormikNewDateTime
                                id='dobDoi'
                                fieldName='dobDoi'
                                formik={creditorFormik}
                                maxDate={(new Date())}
                                label={t('Date_Of_Incorporation')}
                                required={(CreditorsType.INDIVIDUAL !== creditorFormik.values.partyCd 
                                    && CreditorsType.BANK !== creditorFormik.values.partyCd)}
                                handleChange={creditorFormik.handleChange}
                                handleBlur={creditorFormik.handleBlur}
                                size='small'
                                onlyDate={true}
                                disabled={creditorFormik.values.partyId && searchPartyDetails?.dobDoi}
                            />
                    </Grid>}
                    {creditorFormik.values.claimantId === null && CreditorsType.BANK !== creditorFormik.values.partyCd && <Grid item xs={4}>
                        <FormikLabelTextField
                            id="email"
                            fieldName="email"
                            label={t("Email")}
                            formik={creditorFormik}
                            size="small"
                            required={true}
                            handleChange={(event) => setFormikValueWithLowerCase(event, creditorFormik)}
                            handleBlur={creditorFormik.handleBlur}
                            disabled={creditorFormik.values.partyId && searchPartyDetails?.email}
                        />
                    </Grid>}
                    {creditorFormik.values.claimantId === null && CreditorsType.BANK !== creditorFormik.values.partyCd && <Grid item xs={3}>
                        <FormikLabelMuiPhone
                            id="phoneNumber"
                            fieldName="phoneNumber"
                            label={t("Mobile_Number")}
                            countryCodeName="extension"
                            formik={creditorFormik}
                            size="small"
                            required={true}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                            disabled={creditorFormik.values.partyId && searchPartyDetails?.phoneNumber}
                        />
                    </Grid>}
                    {RelationshipCd.FINANCIAL_CLAIMANT === relationshipCd && classOfCreditor.length > 0 && isHomeBuyer && <Grid item xs={3}>
                        <SelectLabelController
                            id="creditorClasses"
                            fieldName="creditorClasses"
                            label={t("Class_Of_Creditor")}
                            formik={creditorFormik}
                            size="small"
                            // required={true}
                            menuItems={classOfCreditor}
                            handleChange={(event) => {handelClassOfCreditorChange(event.target.value)}}
                            handleBlur={creditorFormik.handleBlur}
                            menuFieldId={'name'}
                            menuFieldTitle={'name'}
                            valueField={'name'}
                        />
                    </Grid>}
                    {RelationshipCd.FINANCIAL_CLAIMANT === relationshipCd && creditorFormik.values.creditorClasses && <Grid item xs={3}>
                        <SelectLabelController
                            id="preferredAuthorizedRepresentativeId"
                            fieldName="preferredAuthorizedRepresentativeId"
                            label={t("Preferred_IP_Authorized_Representative")}
                            formik={creditorFormik}
                            size="small"
                            required={creditorFormik.values.creditorClasses}
                            menuItems={classAuthReps}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                            menuFieldId={'arCreditorClassId'}
                            menuFieldTitle={'authorizedRepresentative'}
                            valueField={'arCreditorClassId'}
                        />
                    </Grid>}
                    
                </Grid>
                {RelationshipCd.EMPLOYEE_CLAIMANT !== relationshipCd && RelationshipCd.WORKMAN_CLAIMANT !== relationshipCd 
                && caseStage === Stage.LIQUIDATION && <Grid container spacing={2} sx={{paddingTop: '8px'}}>
                    <Grid item xs={9}>
                        <FormikLabelTextField
                            id="coPartners"
                            fieldName="coPartners"
                            label={`${t("Co_Partners_Names")} ( ${t("Enter_Names_Separated_By_Comma")} )`}
                            formik={creditorFormik}
                            size="small"
                            required={false}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormikLabelNumericFormat
                            id="coPartnersClaim"
                            fieldName="coPartnersClaim"
                            formik={creditorFormik}
                            size="small"
                            label={t('Co_Partners_Claim_Rs')}
                            isThousandSeparator={true}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'coPartnersConsideration'}
                            fieldName='coPartnersConsideration'
                            label={t('Co_Partners_Consideration')}
                            formik={creditorFormik}
                            minRows={1}
                            maxRows={2}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                        />
                    </Grid>
                </Grid>}
            </Stack>
            {<Stack>
                <AddressDetail addressTitle={t('Address_Details')} addressFormik={creditorFormik}
                    // editMode={!creditorFormik.values.partyId} 
                    isDisabled={creditorFormik.values.partyId && searchPartyDetails?.addressLine1} required={true}/>
            </Stack>}
            {creditorFormik.values.claimantId !== null && <Stack>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <StepperSectionHeader title={t('Identification')} />
                        <IdenticationList partyId={creditorFormik.values.partyId} partyCd={creditorFormik.values.partyCd}
                                canAdd={true} canModify={true} canDelete={true}/>
                    </Grid>
                    <Grid item xs={4}>
                        <StepperSectionHeader title={t('Email_Address')} />
                        <EmailList partyId={creditorFormik.values.partyId} canAdd={true} canModify={true} canDelete={true}/>
                    </Grid>
                    <Grid item xs={4}>
                        <StepperSectionHeader title={t('Phone_Number')} />
                        <PhoneNumberList partyId={creditorFormik.values.partyId} canAdd={true} canModify={true} canDelete={true}/>
                    </Grid>
                </Grid>
            </Stack>}
            <Stack>
                <StepperSectionHeader title={t('Additional_Required_Information')} />
                <Stack direction="row" mt={2} spacing={2}>
                    <StyledCard sx={{width: '50% !important'}}>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                sx={{display: 'flex', flexDirection: 'row'}}>
                            {/* <Stack direction='column' spacing={0.5} m={'5px !important'}>
                                <FaHandshakeSimple style={{ fontSize: '2rem', color: 'darkblue' }} />
                            </Stack> */}
                            <Stack direction='column' spacing={0.5} mt={'5px !important'}>
                                <Stack direction='row'>
                                    <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Any_Mutual_Dues_To_Setoff_Rs')}</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <FormikLabelNumericFormat
                                        id="mutualDuesSetOff"
                                        fieldName="mutualDuesSetOff"
                                        formik={creditorFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={false}
                                        handleChange={creditorFormik.handleChange}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledCard>
                    <StyledCard sx={{width: '50% !important'}}>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                sx={{display: 'flex', flexDirection: 'row'}}>
                            {/* <Stack direction='column' spacing={0.5} m={'5px !important'}>
                                <GiPayMoney style={{ fontSize: '2rem', color: 'darkblue' }} />
                            </Stack> */}
                            <Stack direction='column' spacing={0.5} mt={'5px !important'}>
                                <Stack direction='row'>
                                    <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Amount_Owed_To_Corporate_Debtor_Rs')}</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <FormikLabelNumericFormat
                                        id="amountOwedToDebtor"
                                        fieldName="amountOwedToDebtor"
                                        formik={creditorFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={false}
                                        handleChange={creditorFormik.handleChange}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledCard>
                    <StyledCard sx={{width: '50% !important'}}>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                sx={{display: 'flex', flexDirection: 'row'}}>
                            {/* <Stack direction='column' spacing={0.5} m={'5px !important'}>
                                <FaHandshakeSimple style={{ fontSize: '2rem', color: 'darkblue' }} />
                            </Stack> */}
                            <Stack direction='column' spacing={0.5} mt={'5px !important'}>
                                <Stack direction='row'>
                                    <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Contigent_Claim_Rs')}</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <FormikLabelNumericFormat
                                        id="contigent"
                                        fieldName="contigent"
                                        formik={creditorFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={false}
                                        handleChange={creditorFormik.handleChange}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledCard>
                    <StyledCard sx={{width: '50% !important'}}>
                        <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={0.5}
                                sx={{display: 'flex', flexDirection: 'row'}}>
                            {/* <Stack direction='column' spacing={0.5} m={'5px !important'}>
                                <FaHandshakeSimple style={{ fontSize: '2rem', color: 'darkblue' }} />
                            </Stack> */}
                            <Stack direction='column' spacing={0.5} mt={'5px !important'}>
                                <Stack direction='row'>
                                    <Typography color='textSecondary' variant="body2" style={{ fontWeight: 'bold' }}>{t('Reconciliation_Amount_Rs')}</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <FormikLabelNumericFormat
                                        id="reconciliationAmount"
                                        fieldName="reconciliationAmount"
                                        formik={creditorFormik}
                                        size="small"
                                        isThousandSeparator={true}
                                        required={false}
                                        handleChange={creditorFormik.handleChange}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    </StyledCard>
                </Stack>
                <Grid container spacing={2} mt={1}>
                    {RelationshipCd.EMPLOYEE_CLAIMANT !== relationshipCd && RelationshipCd.WORKMAN_CLAIMANT 
                            !== relationshipCd && <Grid item xs={3}>
                        <YesNoSwitchController
                            title={t('Are_You_A_Secured_Creditor')} 
                            description={t('Secured_Creditor_Helper_Text')}
                            fieldName="secured"
                            id="secured"
                            formik={creditorFormik}
                            required/>
                    </Grid>}
                    <Grid item xs={3}>
                        <YesNoSwitchController 
                            title={t('Are_You_Related_To_Corporate_Debtor')} 
                            description={t('Related_To_Corporate_Debtor_Helper_Text')}
                            fieldName="realtedParty"
                            id="realtedParty"
                            formik={creditorFormik}
                            isTextField={creditorFormik.values.realtedParty}
                            textFieldId={'natureOfRelatedParty'}
                            textFieldfieldName={'natureOfRelatedParty'}
                            textFieldlabel={t('Specify_The_Relationship')}
                            required/>
                    </Grid>
                    <Grid item xs={3}>
                        <YesNoSwitchController 
                            title={t('Are_You_Related_Under_Section_21_2')} 
                            description={t('Related_Under_Section-21(2)_Helper_Text')}
                            fieldName="relatedUnderSection"
                            id="relatedUnderSection"
                            formik={creditorFormik}
                            required
                        />
                    </Grid>
                    {(!claimSubmission && RelationshipCd.FINANCIAL_CLAIMANT === relationshipCd) && <Grid item xs={3}>
                        <YesNoSwitchController 
                            title={t('Voting_Right')} 
                            description={t('')}
                            fieldName="voteRight"
                            id="voteRight"
                            formik={creditorFormik}
                            isNumberField={creditorFormik.values.voteRight}
                            textFieldId={'voteShare'}
                            textFieldfieldName={'voteShare'}
                            textFieldlabel={t('Voting_Share')}
                        />
                    </Grid>}
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                        <TextAreaLabelController
                            id={'retentionOfTitleArrangement'}
                            fieldName='retentionOfTitleArrangement'
                            label={t('Retention_Of_Title_Arrangement')}
                            formik={creditorFormik}
                            minRows={1}
                            maxRows={3}
                            handleChange={creditorFormik.handleChange}
                            handleBlur={creditorFormik.handleBlur}
                        />
                    </Grid>
                </Grid>
            </Stack>
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} isCancel={seekConfirmation.isCancel}
            />}
        </Stack>
    )
}