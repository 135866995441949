import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, IconButton, Stack, TableBody, TableHead, Tooltip, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoEye } from "react-icons/io5";
import { MdDelete } from 'react-icons/md';
import { AddBankProfile } from './AddBankProfile';
import BankDetailsPage from './BankDetailsPage';
import { ICMSAxiosInterceptor } from '../../../config/axios.interceptor';
import { IcmsMuiTable, IcmsTableContainer, IcmsTableRow, StyledTableCell } from '../../generic-styles/NavPageContainerStyles';
import ICMSLoader from '../../icms-loader/ICMSLoader';
import IcmsSnackbar from '../../icms-snackbar/IcmsSnackbar';
import ICMSConfirmDialog from '../../ConfirmationDialog/ICMSConfirmDialog';
import { ICMSTheme } from '../../../theme/Theme';

const BankListProfile = ({partyId}) => {
    const [bankListData, setBankListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState({
        open: false,
        bankRow: {},
    });
    const [openBankDetails, setOpenBankDetails] = useState({
        open: false,
        bankRow: {},
    });
    const [tableContainerPosition, setTableContainerPosition] = useState(200);
    const headerRef = useRef();
    const footerRef = useRef();
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const columns = [
        { id: 'name', title: t('Account_Holder_Name'), align: 'left' },
        { id: 'number', title: t('Account_Number'), align: 'left' },
        { id: 'account', title: t('Account_Type'), align: 'left' },
        { id: 'ifsc-code', title: t('IFSC_Code'), align: 'left' },
        { id: 'action', title: t('Actions'), align: 'left' }
    ]

    const handleDeleteBank = (row) => {
        setSeekConfirmation({
            show: true,
            title: t("Confirmation"),
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To_Delete')} <b>{`${row.accountHolderName} Account`}</b>?</Fragment>,
            onDisAgree: resetConfirmation,
            onAgree: () => deleteBank(row)
        })
    }

    const resetConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        })
    }

    const deleteBank = (row) => {
        setLoading(true)
        ICMSAxiosInterceptor.delete(`bank-account/${row.bankAccountUuid}`).then(() => {
            setLoading(false)
            setMessage({ show: true, message: t("Bank_Successfully_Deleted"), severity: 'success' });
            bankList()
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
        })
        resetConfirmation()
    }

    const addBankDetail = (row) => {
        setOpenDialog({
            open: true,
            bankRow: row ? row : {}
        })
    }

    const openBankDetail = (row, index) => {
        setOpenBankDetails({
            open: true,
            bankRow: row,
            rowIndex: index
        })
    }

    const bankList = () => {
        setLoading(true)
        ICMSAxiosInterceptor.get(`bank-account/list`, {}).then((response) => {
            setBankListData(response)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ show: true, message: error.message, severity: 'error' });
        })
    }

    useEffect(() => {
        setTableContainerPosition(headerRef.current.getBoundingClientRect().bottom + footerRef.current.getBoundingClientRect().height);
        bankList()
    }, [])

    const handleClose = (reload) => {
        if (reload) {
            bankList()
        }
        setOpenDialog({
            open: false,
            bankRow: {}
        })
        setOpenBankDetails({
            open: false,
            bankRow: {}
        })
    }

    return (
        <Fragment>
            <Grid container direction="row" alignItems="baseline" sx={{ padding: '0px 20px' }} ref={headerRef}>
                <Grid item xs={12} sm={10} md={10} lg={10}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Typography className="case-list-title" sx={{ marginLeft: '3rem' }} data-testid='bank' >
                            {t("Bank_Details")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="baseline">
                        <Button id="add-bank"
                            data-testid='add-bank'
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ margin: '10px', textTransform: 'capitalize' }}
                            onClick={() => addBankDetail()}
                        >
                            {t("Add_Bank")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Stack justifyContent='center' alignItems='center' >
                <IcmsTableContainer position={tableContainerPosition} data-testid='table-container'>
                    <IcmsMuiTable stickyHeader aria-label="sticky table" data-testid='table'>
                        <TableHead data-testid='table-head'>
                            <IcmsTableRow>
                                {
                                    columns.map((column, index) => {
                                        return (
                                            <StyledTableCell key={column.id} data-testid={column.id} width={column.width} align={column.align}>{column.title}</StyledTableCell>
                                        )
                                    })
                                }
                            </IcmsTableRow>
                        </TableHead>
                        <TableBody data-testid='table-body'>
                            {bankListData.length > 0 ? bankListData.map((row, index) => {
                                return (
                                    <IcmsTableRow key={row.id} data-testid='table-row'>
                                        <StyledTableCell data-testid='name' align='left'>{row.accountHolderName}</StyledTableCell>
                                        <StyledTableCell data-testid='description' align='left' >{row.accountNumber}</StyledTableCell>
                                        <StyledTableCell data-testid='start-date' align='left' >{row.bankAccountLocale}</StyledTableCell>
                                        <StyledTableCell data-testid='start-date' align='left' >{row.ifsc}</StyledTableCell>
                                        <StyledTableCell data-testid='action' align='left' sx={{ p: '0px' }} >
                                            <Fragment>
                                                {<Tooltip title={t('Edit')}>
                                                    <IconButton onClick={() => addBankDetail(row)}>
                                                        <EditIcon data-testid='edit-icon' style={{ cursor: 'pointer', fontSize: '1.3rem', color: ICMSTheme.palette.primary.main }} />
                                                    </IconButton>
                                                </Tooltip>}
                                                {<Tooltip title={t('Delete')}>
                                                    <IconButton onClick={() => handleDeleteBank(row)} color='error'>
                                                        <MdDelete data-testid='delete-icon' style={{ cursor: 'pointer', fontSize: '1.3rem' }} />
                                                    </IconButton>
                                                </Tooltip>}
                                                {<Tooltip title={t('View')}>
                                                    <IconButton onClick={() => openBankDetail(row, index)}>
                                                        <IoEye data-testid='view-icon' style={{ cursor: 'pointer', fontSize: '1.3rem', color: ICMSTheme.palette.primary.main }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Fragment>
                                        </StyledTableCell>
                                    </IcmsTableRow>
                                )
                            }) :
                                <IcmsTableRow>
                                    <StyledTableCell colSpan={5} data-testid='no-record'>{t("No_Record_Found")}</StyledTableCell>
                                </IcmsTableRow>
                            }
                        </TableBody>
                    </IcmsMuiTable>
                </IcmsTableContainer>
            </Stack>
            <Grid item xs={12} ref={footerRef}>
            </Grid>
            {openDialog.open && <AddBankProfile data={openDialog} handleClose={handleClose} setMessage={setMessage} partyId={partyId}/>}
            {openBankDetails.open && <BankDetailsPage data={openBankDetails} bankListData={bankListData} setMessage={setMessage} handleClose={handleClose} />}
            {loading && <ICMSLoader show={loading} />}
            {message.show && <IcmsSnackbar show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            {seekConfirmation.show && <ICMSConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
        </Fragment>
    )
}

export default BankListProfile;