import { ICMSAxiosInterceptor } from "../../../config/axios.interceptor";

const createClaimant = (caseId, claimantResource, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.post(`case/${caseId}/claimant`, claimantResource).then(response => {
        if(response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const updateClaimant = (caseId, claimantId, claimantResource, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.put(`claimant/${caseId}/${claimantId}`, claimantResource).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const getClaimant = (caseId, claimantId, caseStage, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.get(`case/${caseId}/claimant/${claimantId}/case-stage/${caseStage}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const deleteClaimant = (caseId, claimantId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.delete(`case/${caseId}/claimant/${claimantId}`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const listClaimant = (caseId, page, rowPerPage, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.post(`case/${caseId}/claimant/list?page=${page}&limit=${rowPerPage}&asc=true`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

const listClassOfCreditor = (caseId, handleSuccessCallback, handleErrorCallBack) => {

    ICMSAxiosInterceptor.get(`case/${caseId}/creditor-class/list`).then(response => {
        if (response) {
            handleSuccessCallback(response);
        }
    }).catch(error => {
        handleErrorCallBack(error);
    })
}

export { createClaimant, updateClaimant, getClaimant, deleteClaimant, listClaimant, listClassOfCreditor }