import { Person } from "@mui/icons-material";
import { BsPersonFill } from "react-icons/bs";
import { FaBriefcase } from "react-icons/fa";
import { FaCartFlatbed, FaMoneyBillTrendUp } from "react-icons/fa6";
import { HiMiniWrenchScrewdriver, HiUserGroup } from "react-icons/hi2";
import { ImHome3 } from "react-icons/im";
import { Stage } from "../../../../common/GenericCodes";
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';

export const ClaimantIcon = ({ icon, personColor }) => {
    return (
        <span
            style={{
                position: 'relative'
            }}
        >
            <BsPersonFill style={{ fontSize: '3rem', color: personColor ? personColor : 'inherit' }} />
            {icon}
        </span>
    )
}

export const FCIcon = ({ color }) => {
    return (
        <ClaimantIcon icon={<FaMoneyBillTrendUp style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
    )
}

// export const FCHBIcon = ({ color }) => {
//     return (
//         <ClaimantIcon icon={<ImHome3 style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
//     )
// }

export const SupplierIcon = ({ color }) => {
    return (
        <ClaimantIcon icon={<FaCartFlatbed style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
    )
}

export const EmployeeIcon = ({ color }) => {
    return (
        <ClaimantIcon icon={<FaBriefcase style={{ fontSize: '1.8rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
    )
}

export const WorkmanIcon = ({ color }) => {
    return (
        <ClaimantIcon icon={<HiMiniWrenchScrewdriver style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
    )
}

export const OtherCreditorStakeIcon = ({ color }) => {
    return (
        <ClaimantIcon icon={<HiUserGroup style={{ fontSize: '2rem', position: 'absolute', right: '-16px', bottom: '0px', color: color ? color : 'inherit' }} />} />
    )
}

export const ClaimSideValue = {
    FINANCIAL: 'claim-fc',
    // FINANCIAL_HB: 'hb',
    OC_SUPPLIER: 'claim-supplier', // uncomment this while creating pull request
    OC_EMPLOYEE: 'claim-employee',
    OC_WORKMAN: 'claim-workman',
    OTHER_CREDITOR: 'claim-othercreditor',
    STAKEHOLDER: 'claim-stakeholder',
    AUTH_REP: 'claim-authrep',
    CLASS_OF_CREDITOR: 'committee-classofcreditor'
}

// left navigation order will take as in this list
export const ClaimSideMenu = [
    {
        id: 'fc',
        value: ClaimSideValue.FINANCIAL,
        title: 'Financial Creditor',
        icon: <FaMoneyBillTrendUp style={{fontSize: '1.5rem'}} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Financial Lenders & Allottees (Homebuyers)'
    },
    // {
    //     id: 'hb',
    //     value: ClaimSideValue.FINANCIAL_HB,
    //     title: 'Home Buyer',
    //     icon: <ImHome3 style={{ fontSize: '1.5rem' }} />
    // },
    {
        id: 'supp',
        value: ClaimSideValue.OC_SUPPLIER,
        title: 'Supplier',
        icon: <FaCartFlatbed style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Operational creditors who have operational debt(other than employees and workman)'
    },
    {
        id: 'emp',
        value: ClaimSideValue.OC_EMPLOYEE,
        title: 'Employee',
        icon: <FaBriefcase style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Employees who have operational claim'
    },
    {
        id: 'work',
        value: ClaimSideValue.OC_WORKMAN,
        title: 'Workman',
        icon: <HiMiniWrenchScrewdriver style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Workman who have operational claim'
    },
    {
        id: 'other',
        value: ClaimSideValue.OTHER_CREDITOR,
        title: 'Other Creditor',
        icon: <HiUserGroup style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION],
        toolTip: 'Creditors, apart from operational and financial creditors, who have a claim'
    },
    {
        id: 'stake',
        value: ClaimSideValue.STAKEHOLDER,
        title: 'Stakeholder',
        icon: <HiUserGroup style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.LIQUIDATION]
    },
    {
        id: 'creditor',
        value: ClaimSideValue.CLASS_OF_CREDITOR,
        title: 'Class Of Creditors',
        icon: <BsFillCreditCard2FrontFill style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Class of Creditors'
    },
    {
        id: 'auth-rep',
        value: ClaimSideValue.AUTH_REP,
        title: 'Auth.Rep.',
        icon: <HiUserGroup style={{ fontSize: '1.5rem' }} />,
        stage: [Stage.RESOLUTION, Stage.LIQUIDATION],
        toolTip: 'Authorised Representative of workman and employees'
    },
]
