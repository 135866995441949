import { isValidPhoneNumber }           from 'react-phone-number-input';
import { 
    IdentificationType,
    RegEx,
    PartyType
}                                       from '../../../../../common/GenericCodes';
import * as Yup                         from 'yup';

const resolutionApplicantValidationSchema = Yup.object().shape({
    partyCd: Yup.string().required('Resolution Applicant Type is Required'),
    identificationCd: Yup.string().required("Field is mandatory"),
    name: Yup.string().required("Field is mandatory").matches(RegEx.USERNAME, 'Invalid Name'),
    identification: Yup.string().required('Field is mandatory')
        .when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.CIN},
            then: () => Yup.string().required('Field is mandatory').matches(RegEx.CIN, 'Invalid CIN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
            then: () => Yup.string().required('Field is mandatory').matches(RegEx.PAN, 'Invalid PAN')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
            then: () => Yup.string().required('Field is mandatory').matches(RegEx.PASSPORT, 'Invalid Passport No.')
        }).when('identificationCd', {
            is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTER_ID},
            then: () => Yup.string().required('Field is mandatory').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
        }
    ), 
    dobDoi: Yup.date().when('partyCd', {
        is: (partyCd) => {
                return (partyCd === PartyType.COMPANY || partyCd === PartyType.PARTNERSHIPFIRM
                    || partyCd === PartyType.PROPBBUSINESS)},
        then: () => Yup.date().required("Field is mandatory"),
        otherwise: () => Yup.date().nullable(true),
    }),
    email: Yup.string().required("Field is mandatory").matches(RegEx.EMAIL, 'Invalid Email'),
    phoneNumber: Yup.string().required("Field is mandatory")
        .test("phoneNumber", "Mobile Number is not valid", (str, context) => {
        const pn = isValidPhoneNumber(context.parent['extension'] + str);
        return pn;
    }),
    resoultionApplicantCd:  Yup.date().required("Field is mandatory"),
    eoiResponseTime: Yup.date().required("Field is mandatory"),
    addressLine1: Yup.string().required("Field is mandatory").min(2, 'Enter minimum of 2 letters').max(100, 'Limit is till 100 characters'),
    country: Yup.string().required("Field is mandatory"),
    state: Yup.string().required("Field is mandatory"),
    cityOrTown: Yup.string().required("Field is mandatory"),
    postalCode: Yup.string().matches(RegEx.PIN_CODE, 'Invalid Pin code').required("Field is mandatory"),
    reasonForNotObtainConfidentialUndertaking:  Yup.string().when('obtainedConfidentialUndertaking', {
        is: false,
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Reason for not Obtaining')
    })
})

const authRepValidationSchema = Yup.object().shape({
    identificationCd: Yup.string().required("Field is mandatory"),
    name: Yup.string().required("Field is mandatory").matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    identification: Yup.string().required('Field is mandatory').when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.PAN, 'Invalid PAN')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.PASSPORT, 'Invalid Passport No.')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTER_ID},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
    }),
    relationshipName: Yup.string().required("Field is mandatory").matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Designatin'),
    email: Yup.string().required("Field is mandatory").matches(RegEx.EMAIL, 'Invalid Email'),
    phoneNumber: Yup.string().required("Field is mandatory").test("phoneNumber", "Mobile Number is not valid", (str, context) => {
        const pn = isValidPhoneNumber('+91' + str);
        return pn;
    }),
    addressLine1: Yup.string().required("Field is mandatory").min(2, 'Enter a minimum of 2 letters').max(100, 'Limit is up to 100 characters'),
    country: Yup.string().required("Field is mandatory"),
    state: Yup.string().required("Field is mandatory"),
    cityOrTown: Yup.string().required("Field is mandatory"),
    postalCode: Yup.string().matches(RegEx.PIN_CODE, 'Invalid Pin code').required("Field is mandatory"),
});

const jointRAValidationSchema = Yup.object().shape({
    identificationCd: Yup.string().required("Field is mandatory"),
    name: Yup.string().required("Field is mandatory").matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Name'),
    identification: Yup.string().required('Field is mandatory').when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PAN},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.PAN, 'Invalid PAN')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.PASSPORT},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.PASSPORT, 'Invalid Passport No.')
    }).when('identificationCd', {
        is: (identificationCd) => { return Number(identificationCd) === IdentificationType.VOTER_ID},
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.VOTER_ID, 'Invalid Voter ID')
    }),
    email: Yup.string().required("Field is mandatory").matches(RegEx.EMAIL, 'Invalid Email'),
    phoneNumber: Yup.string().test("phoneNumber", "Mobile Number is not valid", (str, context) => {
        const pn = isValidPhoneNumber('+91' + str);
        return pn;
    }),
    addressLine1: Yup.string().required("Field is mandatory").min(2, 'Enter a minimum of 2 letters').max(100, 'Limit is up to 100 characters'),
    country: Yup.string().required("Field is mandatory"),
    state: Yup.string().required("Field is mandatory"),
    cityOrTown: Yup.string().required("Field is mandatory"),
    postalCode: Yup.string().matches(RegEx.PIN_CODE, 'Invalid Pin code').required("Field is mandatory"),
});

const resolutionPlanValidationSchema = Yup.object().shape({
    planNumber: Yup.string().required("Plan No. mandatory").matches(RegEx.REFERENCE_NUMBER, 'Invalid Plan No.'),
    reasonForReject: Yup.string().when('approved', {
        is: false,
        then: () => Yup.string().required('Field is mandatory').matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Reason for not Obtaining')
    })
});

const provisionValidationSchema = Yup.object().shape({
    stakeholderCd: Yup.string().required("Field is mandatory"),
    claimed: Yup.number().required("Field is mandatory").min(1, 'Should be greater than or equals 1'),
    admited: Yup.number().required("Field is mandatory").min(1, 'Should be greater than or equals 1'),
    provided: Yup.number().required("Field is mandatory").min(1, 'Should be greater than or equals 1')
});

const sourceOfFundValidationSchema = Yup.object().shape({
    dateOfFunding: Yup.string().required("Field is mandatory"),
    amount: Yup.number().required("Field is mandatory").min(1, 'Should be greater than or equals 1'),
    source: Yup.string().required("Field is mandatory").matches(RegEx.COMBINATION_OF_LETTERS_OR_ONLY_LETTERS, 'Invalid Source')
});

export { authRepValidationSchema, resolutionApplicantValidationSchema, resolutionPlanValidationSchema,
    jointRAValidationSchema, provisionValidationSchema, sourceOfFundValidationSchema }