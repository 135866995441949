import React, { Fragment, useState }            from 'react';
import { 
    Button, Dialog, DialogActions, 
    DialogContent, DialogTitle, 
    Grid, Typography, Slide, Stack, 
    CardContent 
}                                               from "@mui/material";
import { BsFilePersonFill, BsPersonFillGear }   from "react-icons/bs";
import { useTranslation }                       from "react-i18next";
import { useFormik }                            from "formik";
import ICMSLoader                               from "../../../../../common/icms-loader/ICMSLoader";
import IcmsSnackbar                             from "../../../../../common/icms-snackbar/IcmsSnackbar";
import { ColorlibStepIconRoot }                 from "../../../../claim-stepper/ClaimStyles";
import AuthRepDetails                           from "./ra-auth-rep/AuthRepDetails";
import ResolutionApplicant                      from "./ResolutionApplicant";
import ResolutionApplicantSteps                 from './ResolutionApplicantSteps';
import { ICMSAxiosInterceptor }                 from "../../../../../config/axios.interceptor";
import { AddressTypes, IdentificationType,
    PartyType, 
    ResolutionApplicantCategoryCd}                                 from "../../../../../common/GenericCodes";
import {
    resolutionApplicantValidationSchema,
    authRepValidationSchema
}                                               from './ResolutionApplicantSchema';
import { ICMSButton }                           from '../../../../../common/icms-styled-components/IcmsStyledComponents';

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <BsFilePersonFill style = {{ fontSize: '1.5rem' }} />,
        2: <BsPersonFillGear style = {{ fontSize: '1.5rem' }} />
    };

    return (
        <ColorlibStepIconRoot
            ownerState = {{ completed, active }}
            className  = {className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const CreateResolutionApplicant = ({open, onClose, caseId, relationshipCd, eoiMinDate, requestFromRA, showSelf}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [message, setMessage] = useState({show: false, message : '', severity: ''});
    const steps = [
        "Resolution Applicant Details",
        "Authorized Person Details"
    ];

    const resolutionApplicantFormik = useFormik({
        initialValues: {
            partyId: '',
            resolutionApplicantId: '',
            partyNameId: '',
            resoultionApplicantCd: ResolutionApplicantCategoryCd.PROSPECTIVE,
            partyCd: PartyType.INDIVIDUAL,
            name: '',
            identificationId: '',
            identificationCd: IdentificationType.PAN,
            identification: '',
            dobDoi: null,
            eoiResponseTime: new Date(),
            rfrpResponseTime: null,
            emailId: '',
            emailContactCd: '',
            email: '',
            phoneNumberId: '',
            phoneNumberContactCd: '',
            phoneNumber: '',
            extension: '+91',
            websiteId: '',
            websiteContactCd: '',
            website: '',
            reason: '',
            partyAddressId: '',
            addressId: '',
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            postalCode: '',
            country: 'India',
            groupToWhichItBelongs: '',
            industry: '',
            compliantWithSection302: null,
            natureOfResolution: '',
            natureOfPerformanceSecurity: '',
            securityValue: '',
            securitySource: '',
            securityDurationStartTime: null,
            securityDurationEndTime: null,
            obtainedConfidentialUndertaking: null,
            undertakingConfidentialityTime: null,
            reasonForNotObtainConfidentialUndertaking: '',
            relationshipCd: relationshipCd,
            loginUid: '',
            partyRelationshipId: ''
        },
        validationSchema: resolutionApplicantValidationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        validateOnMount: true
    })

    
    const authRepFormik = useFormik({
        initialValues: {
            authorizedRepresentativeId: null,
            partyId: '',
            partyNameId: '',
            identificationCd: IdentificationType.PAN,
            name: '',
            identificationId: '',
            identification: '',
            emailId: '',
            emailContactCd: '',
            email: '',
            partyCd: PartyType.INDIVIDUAL,
            phoneNumberId: '',
            phoneNumberContactCd: '',
            phoneNumber: '',
            extension: '+91',
            relationshipName: '',
            partyAddressId: '',
            addressId: '',
            addressCd: AddressTypes.registerdWithIBBI.addressCd,
            addressLine1: '',
            addressLine2: '',
            cityOrTown: '',
            state: '',
            country: 'India',
            partyId: null,
            postalCode: ''
        },
        validationSchema: authRepValidationSchema,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true,
    })

    const createResolutionApplicant = () => {
        setLoading(true);
        let payload = {
            ...resolutionApplicantFormik.values,
            phoneNumber: resolutionApplicantFormik.values?.extension + resolutionApplicantFormik.values?.phoneNumber
        }
        ICMSAxiosInterceptor.post(`/case/${caseId}/ra`, payload).then(response => {
            if (response) {
                resolutionApplicantFormik.setFieldValue('resolutionApplicantId', response.resolutionApplicantId);
                setMessage({show: true, message: t('Resolution_Applicant_Created_Successfully'), severity: 'success'});
            }
            setActiveStep(activeStep + 1);
            setLoading(false);
        }).catch(error => {
            setMessage({show: true, message: error.message, severity: 'error'});
            setLoading(false);
        })
    }

    const createRAAuthRep = () => {
        setLoading(true);
        let payload = {
            ...authRepFormik.values,
            phoneNumber: authRepFormik.values?.extension + authRepFormik.values?.phoneNumber
        }
        ICMSAxiosInterceptor.post(`/case/${caseId}/ra/${resolutionApplicantFormik.values.resolutionApplicantId}/authorized-representative`, 
                payload)
            .then(response => {
                if (response) {
                    setMessage({show: true, message: t("Authorised_Person_Created_Successfully"), severity: 'success'});
                    onClose();
                }
                setLoading(false);
        }).catch(error => {
            setLoading(false);
            setMessage({show: true, message: error.message, severity: 'error'});
        });
    }

    const handleUpdateRA = () =>{
        setActiveStep(activeStep + 1)
    }

    const handlePrevious = () => {
        setActiveStep(activeStep - 1)
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (<ResolutionApplicant formik={resolutionApplicantFormik} isEdit={true}
                    disabled={resolutionApplicantFormik.values.partyId}
                    caseId={caseId} eoiMinDate={eoiMinDate} requestFromRA={requestFromRA} showSelf={showSelf}/>)
            case 1:
                return (<AuthRepDetails formik={authRepFormik} caseId={caseId} 
                    isIndividual={resolutionApplicantFormik.values.partyCd === PartyType.INDIVIDUAL}
                    resolutionApplicantId={resolutionApplicantFormik.values.resolutionApplicantId}
                    disabled={authRepFormik.values.partyId} isEdit={true}/>)
            default: return (<p>{'Not Implemented'}</p>)
        }
    }

    const getStepFooter = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                        <Grid item xs={12}>
                            {!resolutionApplicantFormik.values.resolutionApplicantId &&
                                <ICMSButton variant="contained" onClick={createResolutionApplicant} color="primary"
                                        data-testid="save-and-next-create-RA" 
                                        disabled={!resolutionApplicantFormik.isValid || !resolutionApplicantFormik.dirty}
                                        size='small' sx={{float: 'right', margin: '4px'}}>
                                    {t('Save_and_Next')}
                                </ICMSButton> 
                            }
                            {resolutionApplicantFormik.values.resolutionApplicantId &&
                                <ICMSButton variant="contained" onClick={handleUpdateRA} color="primary" data-testid="next"
                                        size='small' sx={{float: 'right', margin: '4px'}}>
                                    {t('Update_and_Next')}
                                </ICMSButton>
                            }
                            <ICMSButton variant="outlined" onClick={onClose} color="primary"
                                    data-testid="cancel-create-RA" size='small' sx={{float: 'right', margin: '4px'}}>
                                {t('Cancel')}
                            </ICMSButton>
                        </Grid>
                    </Grid>
                );
              
            case 1:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ICMSButton variant="contained" onClick={handlePrevious} color="primary" data-testid="previous"
                                    sx={{ float: 'left' }} size='small'
                            >
                                {t('Previous')}
                            </ICMSButton>
                            <ICMSButton variant="outlined" onClick={onClose} color="primary" data-testid="close" 
                                    sx={{ float: 'right', margin: '4px'}} size='small'
                                >
                                {t('Close')}
                            </ICMSButton>
                            {!authRepFormik.values.authorizedRepresentativeId &&
                                <ICMSButton variant="contained" onClick={createRAAuthRep} color="primary" data-testid="next"
                                        sx={{ float: 'right', margin: '4px'}} disabled={!authRepFormik.isValid} size='small'
                                >
                                    {t('Save')}
                                </ICMSButton>
                            }
                        </Grid>
                    </Grid>
                );

            default:
                return <p>{'Not Implemented'}</p>;
        }
    };

    return (
        <Fragment>
            <Dialog open={open} fullWidth maxWidth='lg' TransitionComponent={Slide}
            PaperProps={{
                style: {
                    minHeight: '85vh'
                }
            }}>
                <DialogTitle>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        {requestFromRA ? t('Submit_EOI_As_Resolution_Applicant') : t('Resolution_Applicant')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers sx={{ paddingTop: '1rem !important' }}>
                    <Stack>
                        <ResolutionApplicantSteps steps={steps} activeStep={activeStep} ColorlibStepIcon={ColorlibStepIcon} />
                        <CardContent sx={{ mt: 2, mb: 1}}>
                            {steps.map((value, index) => {
                                return (
                                    <Stack key={index}>
                                        {activeStep === index && getStepContent(activeStep)}
                                    </Stack>
                                )
                            })}
                        </CardContent>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ gap: '1rem', justifyContent: 'center' }}>
                    {steps.map((value, index) => {
                        return (
                            <Fragment key={index}>
                                {activeStep === index && getStepFooter(activeStep)}
                            </Fragment>
                        )
                    })}
                </DialogActions>
                { loading && <ICMSLoader show={loading}/> }
            </Dialog>
            { message.show && <IcmsSnackbar 
                show={message.show} message={message.message} severity={message.severity}
                handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })}/> }
        </Fragment>
    )
}

export default CreateResolutionApplicant;