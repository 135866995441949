
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import IcmsSnackbar from '../icms-snackbar/IcmsSnackbar';


const ICMSConfirmDialog = (props) => {
    const { required, isConfirmTextMandatory, withConfirmText, placeholder, label } = props
    const { icon } = {
        ...props, icon: props.icon || <QuestionMarkIcon color='primary'
            sx={{ fontSize: '2.5rem !important', background: 'darkorange', padding: '5px', borderRadius: '50%' }}
        />
    }
    const [open, setOpen] = useState(props.show);
    const [title] = useState(props.title);
    const [message] = useState(props.message);
    const [isCancel] = useState(props.isCancel != null ? props.isCancel : true)
    const [agreeBtnLabel] = useState(props.agreeBtnLabel != null ? props.agreeBtnLabel : 'Yes');
    const [disAgreeBtnLabel] = useState(props.disAgreeBtnLabel != null ? props.disAgreeBtnLabel : 'No');
    const [reasonValue, setReasonValue] = useState('')
    const [confirmMandatory] = useState(isConfirmTextMandatory ? isConfirmTextMandatory : false);
    const [errorMessage, setMessage] = useState({
        show: false,
        message: null,
        severity: null
    })

    const handleDisAgree = () => {
        setOpen(false);
        props.onDisAgree();
    };

    const handleAgree = () => {
        setOpen(false);
        props.onAgree(reasonValue);
    };

    const handleReasonChange = (event) => {
        setReasonValue(event.target.value)
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title" sx={{ background: 'black' }}>
                    <Stack justifyContent='center' alignItems='center' sx={{ gap: '0.6rem' }}>
                        {icon}
                        {/* <Typography variant='h5' sx={{ color: (theme) => theme.palette.primary.main }}>{title}</Typography> */}
                        <Typography variant='h5' sx={{ color: 'aliceblue' }}>{title}</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '4vh !important', paddingBottom: '4vh !important', background: '#f9f9f9' }}>
                    <DialogContentText id="dialog-description" style={{ textAlign: 'center', fontWeight: '500', color: 'black' }}>
                        {message}
                    </DialogContentText><br />
                    {withConfirmText && <TextField variant='standard' required={required} fullWidth multiline label={label} placeholder={placeholder} value={reasonValue} onChange={handleReasonChange} />}
                </DialogContent>
                <DialogActions sx={{ padding: '1rem 3.5rem !important', justifyContent: 'center !important', gap: '1rem !important', background: '#f9f9f9' }}>
                    {isCancel && <Button id="popup-no" sx={{ width: '100%' }} onClick={handleDisAgree} color="primary" variant='outlined'>
                        {disAgreeBtnLabel}
                    </Button>}
                    <Button onClick={handleAgree} id="popup-yes" sx={{ width: '100%' }} disabled={confirmMandatory && reasonValue === ''} color="primary" variant='contained' autoFocus>
                        {agreeBtnLabel}
                    </Button>
                    {/* </Stack> */}
                </DialogActions>
                {errorMessage.show && <IcmsSnackbar show={errorMessage.show} message={errorMessage.message} severity={errorMessage.severity}
                    handleResetOnClose={() => setMessage({ show: false, message: '', severity: '' })} />}
            </Dialog>
        </div>
    );
}

export default ICMSConfirmDialog;
